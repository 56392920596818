/* 전체 스크린 스타일 */
.screen {
  display: flex;
  background-color: #fff;
  align-items: center;
  padding-top: 5rem;
}

.rightGroup {
  display: flex;
  justify-content: flex-end;
}

.textGroup {
  margin-bottom: 2rem;
}
.textGroup2 {
  display: flex;
}

.leftImgGroup,
.rightImgGroup {
  position: relative;
  height: 100%;
}

.leftImgGroup {
  padding-top: 5rem;
}

.rightImgGroup {
  padding-bottom: 12rem;
  height: min-content;
}

.leftImgGroup .leftBadge {
  position: absolute;
  bottom: 15%;
  left: -15%;
  max-width: 70%;
}

.rightImgGroup .rigthBadge {
  position: absolute;
  right: 15%;
  top: 20%;
  max-width: 120%;
}

/* 이미지 플로팅 애니메이션 */
.imageFloating {
  animation: floating 2s infinite ease-in-out;
}

/* 프롤팅 애니메이션 키프레임 */
@keyframes floating {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

@media (max-width: 1220px) {
  .screen {
    min-height: 100vh;
    padding: 2rem 0;
  }

  .screen .leftGroup,
  .screen .rightGroup {
    justify-content: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .badgeWrap {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .textGroup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .leftImgGroup {
    padding-top: 2rem;
  }

  .leftImgGroup .leftBadge {
    left: -20%;
    max-width: 100%;
  }

  .rightImgGroup {
    padding-bottom: 3rem;
    padding-top: 4rem;
    padding-left: 1.5rem;
  }

  .rightImgGroup .rigthBadge {
    right: 8%;
  }
}
