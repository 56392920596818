.screen {
  display: flex;
  background-color: #14054e;
  min-height: 100vh;
  align-items: center;
  padding: 2rem 0;
}

.rightGroup {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
}

.rightImgGroup {
  position: relative;
  margin-top: 3rem;
}

.rightPhoneLogo {
  position: absolute;
  z-index: 1;
  right: 26%;
  bottom: 40%;
  width: 150px;
}

@media (max-width: 1022px) {
  .leftGroup,
  .rightGroup {
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .appButtonGroup {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
