/* 전체 스크린 스타일 */
.screen {
  display: flex;
  min-height: 100vh;
  background-color: #fafafa;
  align-items: center;
  padding: 2rem 0;
}

/* 왼쪽 그룹 패딩 설정 */
.leftGroup {
  padding: 2rem 0;
}

/* 오른쪽 그룹 스타일 */
.screen .rightGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}

/* 텍스트 그룹 스타일 */
.textGroup {
  display: flex;
  text-align: right;
  padding: 0;
  align-items: center;
  justify-content: center;
}

/* 텍스트 그룹 내 이미지 패딩 설정 */
.textGroup img {
  padding-top: 2rem;
}

/* 미디어 쿼리를 사용하여 1022px 이하의 화면에서 스타일 변경 */
@media (max-width: 1022px) {
  /* 이미지 그룹 가운데 정렬 */
  .imageGroup {
    text-align: center;
  }
  /* 오른쪽 그룹 가운데 정렬 및 방향 변경 */
  .screen .rightGroup {
    text-align: center;
    align-items: center;
    flex-direction: column;
  }
  .textGroup img {
    padding-top: 0;
  }
  .playerText {
    flex-direction: column;
  }
}
