/* src/assets/styles/fonts.css */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Noto Sans Regular'), local('NotoSans-Regular'),
    url('../fonts/Noto_Sans/NotoSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 500;
  src: local('Noto Sans Medium'), local('NotoSans-Medium'),
    url('../fonts/Noto_Sans/NotoSans-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Noto Sans Bold'), local('NotoSans-Bold'),
    url('../fonts/Noto_Sans/NotoSans-Bold.ttf') format('truetype');
}
